<template>
  <section class="montserrat-regular">
    <Banner class="my-5" :banner="bannerText" />
    <div class="container mb-5 text-center">
      <div class="col heading text-center">
        <h2 class="p-color">
          <span style="background-color: var(--secondary-blue)"
            >NUESTRAS CAPACITACIONES
          </span>
        </h2>
      </div>
    </div>
    <div class="row carousel-capacitations">
      <ul class="carousel-container">
        <li class="carousel-items" v-for="(c, i) in capacitations" :key="i">
          <img class="carousel-item__img" :src="c.img" alt="" />
          <div class="carousel-item__details">
            <h6 class="carousel-item__details--title">{{ c.title }}</h6>
            <p class="carousel-item__details--description px-3">
              {{ c.description }}
            </p>
          </div>
        </li>
      </ul>
      <!--End Latest Post Single-->
    </div>
    <!--End Blog Post Row-->
    <!--End Container-->
  </section>
</template>

<script>
import bannerImage from "@/assets/third-banner-producer-01.png";

import Banner from "@/components/Banner.vue";
import capacitacion1 from "../assets/zama-capacitacion-01.jpeg";
import capacitacion2 from "../assets/zama-capacitacion-02.jpeg";
import capacitacion3 from "../assets/zama-capacitacion-03.jpeg";
import capacitacion4 from "../assets/zama-capacitacion-04.jpeg";
import capacitacion5 from "../assets/zama-capacitacion-05.jpeg";
import capacitacion6 from "../assets/zama-capacitacion-06.jpeg";

export default {
  name: "Education",

  components: { Banner },
  data() {
    return {
      bannerText: {
        goto: "productores",
        gotoId: "#vitaeForm",
        bannerTitleOne: "NUESTRO RITUAL",
        bannerTitleSecond: "ES CAPACITARTE",
        bannerText:
          "Realizamos capacitaciones para nuestros productores y socios estratégicos en Todo Riesgo Operativo, Accidentes Personales, ART, Caución y Seguros de Hogar y Auto.",
        img: bannerImage,
        bgColor: "var(--secondary-blue)",
      },
      capacitations: [
        {
          img: capacitacion1,
          title: "Todo riesgo, Zúrich",
          description:
            "Capacitación exclusiva en Zúrich para nuestros socios estratégicos en el producto Todo Riesgo Operativo.",
        },
        {
          img: capacitacion2,
          title: "Auto, Hogar, Zúrich",
          description:
            "Capacitación exclusiva en las oficinas de Zúrich para uno de nuestros aliados estratégicos: Fidato Argentina, en las coberturas de Auto y Hogar.",
        },
        {
          img: capacitacion3,
          title: "Responsabilidad Civil, Zúrich",
          description:
            "Capacitación de Responsabilidad Civil, producto fundamental para el desarrollo de las carteras de nuestros socios estratégicos.",
        },
        {
          img: capacitacion4,
          title: "Productores, ZAMA",
          description:
            "Capacitación exclusiva en Allianz para productores y colaboradores. Seguimos desarrollando las carreras de nuestros socios de la mano de esta compañía Nº1.",
        },
        {
          img: capacitacion5,
          title: "Caución, Zúrich",
          description:
            "Capacitación exclusiva en las oficinas de Zúrich para nuestros productores en el ramo Caución, un producto de mucha relevancia para el desarrollo de cada uno de nuestros aliados.",
        },
        {
          img: capacitacion6,
          title: "IQ, Soluciones Financieras",
          description:
            "Capacitamos a nuestros socios estratégicos de IQ en distintas ramas y productos para seguir potenciando el desarrollo de nuevos y mejores negocios.",
        },
      ],
    };
  },
};
</script>
