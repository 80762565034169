<template>
  <div>
    <MainBanner class="d-none d-md-flex" :title="mainTitle" />
    <img class="w-100 montserrat-regular d-md-none" :src="images.mainMobile" />
    <SecondSection :information="secondSection" />
    <ContactVitaeForm id="byPartOfZama" />
    <CareerPlan id="careerPlan" />
    <Capacitations id="capacitaciones" />
  </div>
</template>

<script>
import MainBanner from "@/components/MainBanner.vue";
import SecondSection from "@/components/SecondSection.vue";
import ContactVitaeForm from "@/components/ContactVitaeForm.vue";
import CareerPlan from "@/components/CareerPlan.vue";
import Capacitations from "@/components/Capacitations.vue";

import mainBannerImage from "@/assets/main-banner-producer-01.png";
import bannerImage from "@/assets/second-banner-producer-01.png";
import mainMobile from "@/assets/mobile/productores-m.png";

export default {
  name: "Producers",
  components: {
    MainBanner,
    SecondSection,
    ContactVitaeForm,
    CareerPlan,
    Capacitations,
  },
  data() {
    return {
      images: {
        mainMobile,
      },
      mainTitle: {
        first: "NUESTROS",
        second: "PRODUCTORES",
        img: mainBannerImage,
        bgColor: "var(--light-orange)",
      },
      secondSection: {
        goto: "productores",
        gotoId: "#vitaeForm",
        title: "SOMOS UN BROKER QUE RESPONDE",
        text: "Con productores presentes, conectados, rápidos y profesionales.",
        bannerTitleOne: "Somos equipo,",
        bannerTitleSecond: "tenemos garra.",
        bannerText:
          " Contamos con una red de fortaleza. En ZAMA compartimos intereses, motivaciones, objetivos, metas, emociones y experiencias.",
        img: bannerImage,
        bgColor: "var(--secondary-blue)",
      },
    };
  },
};
</script>
