<template>
  <section id="vitaeForm" class="container montserrat-regular col-lg-9">
    <div class="second-section-text py-5 container">
      <div class="heading text-center">
        <h2>CREC&Eacute; CON NOSOTROS</h2>
      </div>
    </div>
    <div class="row pb-5">
      <div class="contact-form-info col-lg-6">
        <h6 class="py-3">
          Queremos conocerte, que seas parte de nuestra tribu.
        </h6>
        <p>
          Nuestro ritual es capacitar a nuestros productores para la mejor
          atención personalizada con nuestros clientes.
        </p>
        <p>
          Si tenés un perfil comercial, emprendedor y querés manejar tu propia
          agenda y horarios, podés convertirte en TU PROPIO JEFE.
        </p>
        <p>
          Unite a nuestra tribu y convertite en productor de Zama, un Broker de
          Seguros con Fortaleza.
        </p>
        <p>
          Desarrollá tu negocio comercializando Seguros de las Campañías Líderes
          del mercado.
        </p>
      </div>
      <div class="col-lg-6">
        <div class="contact-form">
          <form
            id="form"
            enctype="multipart/form-data"
            method="post"
            action="sendmail.php, verify_captcha.php"
            name="form"
            @submit.prevent="contactForm"
          >
            <!-- <input type="hidden" value="Contacto" name="dzToDo" >-->
            <div class="form-group">
              <input
                v-model="form.name"
                class="form-control"
                type="text"
                name="name"
                placeholder="Nombre:"
                onfocus="this.value=''"
                required
              />
            </div>
            <div class="form-group">
              <input
                v-model="form.email"
                class="form-control"
                type="email"
                name="mail"
                placeholder="E-mail:"
                onfocus="this.value=''"
                required
              />
            </div>
            <div class="form-group">
              <input
                v-model="form.phone"
                type="text"
                class="form-control"
                placeholder="Tel/Cel:"
                id="tel"
                name="phone"
                required
              />
            </div>
            <div class="form-group">
              <textarea
                v-model="form.message"
                class="form-control"
                rows="10"
                placeholder="¿Por qué te gustaría ser parte de nuestra tribu?"
                name="message"
                onfocus="this.value=''"
                required
              ></textarea>
            </div>
            <div class="upload-certify-section">
              <div class="h-100">
                <label for="files" class="upload-button">
                  {{ form.file ? form.file.name : "Adjuntar CV" }}
                </label>

                <input
                  id="files"
                  type="file"
                  style="visibility: hidden; display: none"
                  ref="file"
                  @change="selectedFile"
                />
              </div>
              <!-- <div>
              <vue-recaptcha
                @verify="recaptchaVerified"
                sitekey="6LfyxoEcAAAAADiLd_XNrrwJgMS2do-uxn0n7Sxy"
              >
              </vue-recaptcha>
            </div> -->
              <div
                class="g-recaptcha"
                data-sitekey="6LfyxoEcAAAAADiLd_XNrrwJgMS2do-uxn0n7Sxy"
              />
            </div>
            <div id="form-messages">
              {{ serverMessage }}
            </div>
            <div class="contact-btn text-center mt-3">
              <input class="submit-button" type="submit" value="ENVIAR" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        file: "",
        recaptcha: "",
      },
      serverMessage: "",
    };
  },
  methods: {
    selectedFile() {
      this.form.file = this.$refs.file.files[0];
    },
    async contactForm() {
      const formData = new FormData();
      let vm = this;
      const data = Object.assign({}, this.form);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("message", data.message);
      formData.append("attach", data.file);

      var config = {
        method: "post",
        url: "https://www.zamabroker.com.ar/sendmailtest.php",
        data: formData,
      };
      vm.$swal.fire("Gracias", "Por contactarte con nosotros. Te responderemos a la brevedad.", "success");
      axios(config)
        .then(function (response) {
          this.serverMessage = response;
          console.log(JSON.stringify(response.data));
          console.log(this.form);
        })
        .catch(function (error) {
          this.serverMessage = error.response;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.upload-certify-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.upload-button {
  background: #e2e2e2;
  border-radius: 4px;
  height: 100%;
  padding: 20px 10px;
  border: 1px solid #ced4da;
  max-width: 120px;
  overflow-wrap: break-word;
}
</style>
